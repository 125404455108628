$(document).ready(function () {
  if (Pace) {
    Pace.on("done", initSwiper);
  } else {
    $(window).load(initSwiper);
  }

  showLeftTime();

  /* 头部显示本地时间 */
  function showLeftTime() {
    var d = new Date();
    $(".showTime").html(formatDate(d));
    var timeID = setTimeout(showLeftTime, 1000);
  }

  showLeftTime();

  function formatDate(date) {
    return new Date(date)
      .toLocaleString("zh", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
      .replace(/\//gi, "-");
  }

  function initSwiper() {
    const bannerList = window.banner;
    const indexContainer = $("#index-banner.swiper-wrapper");
    const isLoggedIn = $(".before-login.hide").length !== 0;

    bannerList &&
      bannerList !== 0 &&
      bannerList.forEach(function (item) {
        const { imageUrl, redirectUrl, redirectGroup, redirectPage, bannerType } = item;

        if (imageUrl) {
          /**
           * Redirect Group Option
           * 0 - Promo
           * 1 - Join Us
           * 2 - Lottery
           * 3 - Product
           * 4 - Others inner page (Loyalty, Customer Service)
           * 5 - 自定义
           */

          const banner = $(`
            <div class="swiper-slide" data-swiper-autoplay="5000">
              <img ${redirectUrl ? `data-redirect=${redirectUrl}` : ""} class="img" src="${imageUrl}"/>
              <div class="img-bg" style="background-image: url(${imageUrl})"></div>
            </div>
          `);

          banner.click(() =>
            window.initBannerCarouselRedirection({
              redirectUrl,
              redirectGroup,
              redirectPage,
              isLoggedIn,
            })
          );

          /**
           * index append 首页type banner, xycly promo.html no banner
           * 轮播图类型 bannerType
           * 0 - 首页
           * 1 - 优惠活动
           * 2 - 其他
           */
          if (bannerType === 0) {
            indexContainer.append(banner);
          }
        }
      });

    if (bannerList && bannerList.length) {
      var mySwiper = new Swiper(".swiper-container.banner", {
        speed: 400,
        autoplay: {
          delay: 5000,
        },
        loop: true,
        // If we need pagination
        pagination: {
          el: ".swiper-pagination.banner",
        },
      });
    }
  }

  $(".aff-menu .aff-tab").on("click", function () {
    const section = $(this).data("section");
    $(this).addClass("active").siblings().removeClass("active");
    $(`.aff-pages .page[data-section=${section}]`).addClass("active").siblings().removeClass("active");
  });

  animateNum();
});

function animateNum() {
  $(".count").each(function () {
    $(this)
      .prop("Counter", 0)
      .animate(
        {
          Counter: $(this).text(),
        },
        {
          duration: 3000,
          easing: "swing",
          step: function (now) {
            $(this).text(Math.ceil(now));
          },
        }
      );
  });
}

// Promo Content
$(function () {
  if (window.promo) {
    renderPromo(window.promo);
  } else {
    Object.defineProperty(window, "promo", {
      set: function (data) {
        renderPromo(data);
      },
    });
  }

  function displayDate(time) {
    const datetime = new Date(time);
    const year = datetime.getFullYear();
    const month = datetime.getMonth() + 1 < 10 ? `0${datetime.getMonth() + 1}` : datetime.getMonth() + 1;
    const day = datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate();
    return `${year}-${month}-${day}`;
  }

  function displayTime(time) {
    const datetime = new Date(time);
    const min = datetime.getMinutes() < 10 ? `0${datetime.getMinutes()}` : datetime.getMinutes();
    let ampm = "am";
    let hour = datetime.getHours();

    if (hour === 0 && min === "00") {
      hour = 12;
    } else if (hour > 12) {
      hour = hour - 12;
      ampm = "pm";
    }

    return `${hour}:${min}${ampm}`;
  }

  function registerPromo(promoId) {
    api.registerPromo(promoId);
  }

  function renderPromo(promoData) {
    if (Array.isArray(promoData)) {
      var promoWrapper = $(`<div class="promo-wrapper"></div>`);
      promoData.forEach(function (value, index) {
        var promoItem = $(`<div class="promo-panel" data-type="${value.type}"></div>`);
        var dateCreated = displayDate(value.created);
        var timeCreated = displayTime(value.created);

        var promoId = value.promoId;
        var requestToJoin = value.requestToJoin;
        var firstDepositPromoJoinMsg = value.firstDepositPromoJoinMsg;

        promoItem.append(
          $(`
              <div class="promotitle">
                  ${value.imagePath ? `<img src="${value.imagePath}" alt="" />` : ""}
                  <div class="promo-flex">
                    <div class="title">${value.title}</div>
                    <div class="date-created">${dateCreated}, ${timeCreated}</div>
                  </div>
              </div>
              <div class="promocontent">
              ${value.content}
              ${
                requestToJoin
                  ? `
                  <div
                    class="promo--modal--footer"
                    style="display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; height: 50px;"
                  >
                    ${
                      firstDepositPromoJoinMsg
                        ? `
                          <div class="promo--modal--footer--info" style="color: #999;">
                            ${firstDepositPromoJoinMsg}
                          </div>
                        `
                        : `
                          <button
                            class="promo--modal--footer--register-promo"
                            data-id="${promoId}"
                            style="cursor:pointer;padding: 10px 20px;outline: none;width: 100px; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; background-color: #ffec57; color: #103f73;border-radius: 4px; border: 0;"
                          >
                            申请参与
                          </button>
                          `
                    }
                      </div>
                    `
                  : ``
              }
              </div>
            `)
        );

        promoWrapper.append(promoItem);
      });

      $(function () {
        $(".promo--modal--footer--register-promo").on("click", function (e) {
          e.preventDefault();
          var promoId = $(this).data("id");
          registerPromo(promoId);
        });
      });

      $(".promocontent .promo").html(promoWrapper);
    }

    $(".promotitle").on("click", function () {
      $(this).siblings(".promocontent").slideToggle();
    });
  }
});

// hash detection
$(function () {
  function detectHash() {
    var currentHash = location.hash.slice(1);

    $(".tab[data-hash='" + currentHash + "']")
      .addClass("active")
      .siblings(".tab")
      .removeClass("active");

    $(".page[data-hash='" + currentHash + "']")
      .addClass("active")
      .siblings()
      .removeClass("active");

    if ($(".body-wrapper .section-faq").length !== 0) {
      if (currentHash === "aboutUs") {
        $(".menu-item").removeClass("on");
        $(".menu-item.about").addClass("on");
      } else {
        $(".menu-item").removeClass("on");
        $(".menu-item.faqs").addClass("on");
      }
    }
  }

  detectHash();

  $(".tab[data-hash]").click(function (event) {
    event.preventDefault();
    const pageHash = $(this).attr("data-hash");
    location.hash = pageHash;

    $(this).addClass("active").siblings(".tab").removeClass("active");

    $(".pages")
      .find(".page[data-hash='" + pageHash + "']")
      .addClass("active")
      .siblings()
      .removeClass("active");
  });

  $(".footer .main-wrapper a").click(function (e) {
    setTimeout(detectHash, 10);
    $(window).scrollTop(0);
  });
});
